import React from "react";
import theme from "theme";
import { Theme, Box, Text, Image, Button, Link, Strong } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"newspage"} />
		<Helmet>
			<title>
				SPhotonix — Revolutionizing Data Storage & Photonics
			</title>
			<meta name={"description"} content={"Discover SPhotonix’s cutting-edge data storage solutions. We specialize in 5D optical storage technology, pushing the boundaries of durability, scalability, and future-proof innovation for enterprise storage systems. Partner with us to bring next-generation data storage to market."} />
			<meta property={"og:title"} content={"SPhotonix — Revolutionizing Data Storage & Photonics"} />
			<meta property={"og:description"} content={"SPhotonix pioneers the future of data storage with breakthrough 5D optical technology. Our innovative solutions offer durable, scalable, and ultra-secure storage for enterprises looking to safeguard their data for decades. Explore how we are revolutionizing the industry."} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/66b79a6ce778710018d27b85/images/sphotonix-new-logo-white.svg?v=2024-09-17T17:56:51.435Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/66b79a6ce778710018d27b85/images/SPhotonix-fav32w.png?v=2024-09-23T14:28:13.820Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/66b79a6ce778710018d27b85/images/SPhotonix-fav152w.png?v=2024-09-23T14:28:06.690Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/66b79a6ce778710018d27b85/images/SPhotonix-fav152w.png?v=2024-09-23T14:28:06.690Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/66b79a6ce778710018d27b85/images/SPhotonix-fav152w.png?v=2024-09-23T14:28:06.690Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/66b79a6ce778710018d27b85/images/SPhotonix-fav152w.png?v=2024-09-23T14:28:06.690Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/66b79a6ce778710018d27b85/images/SPhotonix-fav270w.png?v=2024-09-23T14:27:58.132Z"} />
			<meta name={"msapplication-TileColor"} content={"#ffffff"} />
		</Helmet>
		<Components.Header position="fixed" right="0px" left="0px" z-index="1">
			<Override slot="linkBox15" color="--accentOrange" />
		</Components.Header>
		<Box height="88px" sm-height="52px" />
		<Box
			padding-top="140px"
			padding-bottom="120px"
			padding-left="190px"
			padding-right="190px"
			background-blend-mode="color-dodge, normal, normal"
			flex-direction="column"
			justify-content="center"
			align-items="center"
			grid-gap="64px"
			display="flex"
			width="100%"
			background="rgba(0, 0, 0, 0) url(https://uploads.quarkly.io/66b79a6ce778710018d27b85/images/technology-bg.webp?v=2024-10-03T21:04:17.534Z) 0% 0% /cover repeat scroll padding-box"
			sm-padding="60px 16px 60px 16px"
			sm-height="auto"
			flex-wrap="no-wrap"
			padding="140px 16px 120px 16px"
		>
			{"  "}
			<Box
				align-self="stretch"
				flex-direction="column"
				justify-content="flex-start"
				align-items="center"
				gap="24px"
				display="flex"
				sm-max-width="100%"
				sm-height="auto"
				max-width="1348px"
				margin="0px auto 0px auto"
				width="100%"
			>
				{"        "}
				<Box
					flex-direction="column"
					justify-content="flex-start"
					align-items="flex-start"
					grid-gap="28px"
					display="flex"
					sm-max-width="100%"
					sm-display="flex"
					sm-grid-row-gap="16px"
				>
					{"      "}
					<Text
						align-self="stretch"
						color="white"
						font="600 72px Epilogue"
						line-height="76px"
						word-wrap="break-word"
						sm-max-width="100%"
						sm-font="600 28px/32px Epilogue"
						sm-margin="0px 0px 0px 0px"
						margin="0px 0px 0px 0px"
						text-align="center"
					>
						SPhotonix News
					</Text>
					{"      "}
					<Text
						opacity={0.8}
						color="white"
						font="400 21px Epilogue"
						line-height="32px"
						word-wrap="break-word"
						sm-max-width="100%"
						sm-font="400 17px/26px Epilogue"
						sm-margin="0px 0px 0px 0px"
						margin="0px 0px 0px 0px"
						max-width="899px"
						text-align="center"
					>
						Stay updated with the latest news and breakthroughs from Sphotonix
					</Text>
					{"    "}
				</Box>
				{"  "}
			</Box>
			{"  "}
			<Box
				align-self="stretch"
				height="auto"
				flex-direction="column"
				justify-content="center"
				align-items="center"
				grid-gap="24px"
				display="flex"
				sm-max-width="100%"
				max-width="1348px"
				margin="0px auto 0px auto"
			>
				{"    "}
				<Box
					padding-left="64px"
					background="rgba(255, 255, 255, 0.90)"
					border="1.50px solid"
					justify-content="center"
					grid-gap="64px"
					display="flex"
					md-display="flex"
					md-flex-wrap="no-wrap"
					md-align-items="center"
					md-justify-content="center"
					md-padding="0px 0px 0px 0px"
					md-height="auto"
					md-grid-row-gap="0px"
					flex-direction="row"
					flex-wrap="no-wrap"
					align-items="center"
					padding="0px 64px 0px 0px"
					md-flex-direction="column"
				>
					{"      "}
					<Box
						display="flex"
						align-self="stretch"
						align-items="center"
						flex-direction="column"
						justify-content="center"
						flex-wrap="no-wrap"
						flex="1 1 0%"
					>
						<Image
							height="100%"
							src="https://uploads.quarkly.io/66b79a6ce778710018d27b85/images/2000-man-data-center-technician-performing-server-maint-vgglal7-1.jpg?v=2025-03-18T20:44:45.336Z"
							md-max-width="100%"
							md-height="auto"
							md-width="auto"
							object-fit="cover"
							width="100%"
							object-position="50% 50%"
							align-self="stretch"
							srcSet="https://smartuploads.quarkly.io/66b79a6ce778710018d27b85/images/2000-man-data-center-technician-performing-server-maint-vgglal7-1.jpg?v=2025-03-18T20%3A44%3A45.336Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/66b79a6ce778710018d27b85/images/2000-man-data-center-technician-performing-server-maint-vgglal7-1.jpg?v=2025-03-18T20%3A44%3A45.336Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/66b79a6ce778710018d27b85/images/2000-man-data-center-technician-performing-server-maint-vgglal7-1.jpg?v=2025-03-18T20%3A44%3A45.336Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/66b79a6ce778710018d27b85/images/2000-man-data-center-technician-performing-server-maint-vgglal7-1.jpg?v=2025-03-18T20%3A44%3A45.336Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/66b79a6ce778710018d27b85/images/2000-man-data-center-technician-performing-server-maint-vgglal7-1.jpg?v=2025-03-18T20%3A44%3A45.336Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/66b79a6ce778710018d27b85/images/2000-man-data-center-technician-performing-server-maint-vgglal7-1.jpg?v=2025-03-18T20%3A44%3A45.336Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/66b79a6ce778710018d27b85/images/2000-man-data-center-technician-performing-server-maint-vgglal7-1.jpg?v=2025-03-18T20%3A44%3A45.336Z&quality=85&w=3200 3200w"
							sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 1100px) 100vw,100vw"
						/>
					</Box>
					<Box
						align-self="stretch"
						padding-top="64px"
						padding-bottom="56px"
						flex-direction="column"
						justify-content="space-between"
						align-items="flex-start"
						display="inline-flex"
						md-height="auto"
						md-padding="0px 0px 0px 0px"
						md-margin="32px 24px 24px 24px"
						flex="1 1 0%"
					>
						<Box
							align-self="stretch"
							flex-direction="column"
							justify-content="flex-start"
							align-items="flex-start"
							grid-gap="40px"
							display="flex"
							md-height="auto"
						>
							{"          "}
							<Box
								align-self="stretch"
								flex-direction="column"
								justify-content="flex-start"
								align-items="flex-start"
								grid-gap="32px"
								display="flex"
								md-height="auto"
							>
								{"            "}
								<Box
									align-self="stretch"
									flex-direction="column"
									justify-content="flex-start"
									align-items="flex-start"
									grid-gap="16px"
									display="flex"
									md-height="auto"
								>
									{"              "}
									<Text
										align-self="stretch"
										color="#080808"
										font=" 700 42px Epilogue"
										line-height="50px"
										word-wrap="break-word"
										margin="0px 0px 0px 0px"
										md-height="auto"
										md-font="600 21px/28px Epilogue"
									>
										Data Centers contributing to 3% of global  carbon footprint, and growing
									</Text>
									{"              "}
									<Text
										align-self="stretch"
										color="#212121"
										font=" 400 21px Epilogue"
										line-height="32px"
										word-wrap="break-word"
										margin="0px 0px 20px 0px"
										md-height="auto"
										md-font="400 15px/24px Epilogue"
									>
										The surge in AI and IoT has driven data growth to 394ZB by 2028, with data centers already responsible for 3% of global emissions. Traditional storage methods consume vast energy and generate e-waste, worsening environmental concerns. SPhotonix’s 5D Optical Storage offers a sustainable solution, storing 360TB on a single glass platter with minimal impact, shaping the future of eco-friendly data storage.
									</Text>
									{"          "}
									<Button
										font="--headline3"
										href="/datacenters3pct"
										padding="0 0 0 0"
										background="0"
										color="--red"
										type="link"
										text-decoration-line="initial"
									>
										Read more   &gt;&gt;
									</Button>
									{"  "}
								</Box>
								{"          "}
							</Box>
							{"        "}
						</Box>
						{"                      "}
					</Box>
					{"          "}
				</Box>
				<Box
					padding-left="64px"
					background="rgba(255, 255, 255, 0.90)"
					border="1.50px solid"
					justify-content="center"
					grid-gap="64px"
					display="flex"
					md-display="flex"
					md-flex-direction="column-reverse"
					md-flex-wrap="no-wrap"
					md-align-items="center"
					md-justify-content="center"
					md-padding="0px 0px 0px 0px"
					md-height="auto"
					md-grid-row-gap="0px"
					flex-direction="row"
					flex-wrap="no-wrap"
					align-items="center"
				>
					{"      "}
					<Box
						align-self="stretch"
						padding-top="64px"
						padding-bottom="56px"
						flex-direction="column"
						justify-content="space-between"
						align-items="flex-start"
						display="inline-flex"
						md-height="auto"
						md-padding="0px 0px 0px 0px"
						md-margin="32px 24px 24px 24px"
						flex="1 1 0%"
					>
						<Box
							align-self="stretch"
							flex-direction="column"
							justify-content="flex-start"
							align-items="flex-start"
							grid-gap="40px"
							display="flex"
							md-height="auto"
						>
							{"          "}
							<Box
								align-self="stretch"
								flex-direction="column"
								justify-content="flex-start"
								align-items="flex-start"
								grid-gap="32px"
								display="flex"
								md-height="auto"
							>
								{"            "}
								<Box
									align-self="stretch"
									flex-direction="column"
									justify-content="flex-start"
									align-items="flex-start"
									grid-gap="16px"
									display="flex"
									md-height="auto"
								>
									{"              "}
									<Text
										align-self="stretch"
										color="#080808"
										font=" 700 42px Epilogue"
										line-height="50px"
										word-wrap="break-word"
										margin="0px 0px 0px 0px"
										md-height="auto"
										md-font="600 21px/28px Epilogue"
									>
										SPhotonix to debut at global-leading technology exhibition 
Photonics West{" "}
									</Text>
									{"              "}
									<Text
										align-self="stretch"
										color="#212121"
										font=" 400 21px Epilogue"
										line-height="32px"
										word-wrap="break-word"
										margin="0px 0px 20px 0px"
										md-height="auto"
										md-font="400 15px/24px Epilogue"
									>
										SPhotonix will make its debut at Photonics West 2025, showcasing its groundbreaking DIC Prisms and Beam Smoothers. These innovations offer superior imaging for microscopy and cost-effective laser beam manipulation, positioning SPhotonix as the sole provider of both technologies. CEO Ilya Kazansky highlights their impact across industries, from space tech to life sciences. This launch follows SPhotonix’s breakthrough in 5D Memory Crystal storage, reinforcing its role as a leader in advanced optical solutions.
									</Text>
									<Button
										font="--headline3"
										href="/spie2025"
										padding="0 0 0 0"
										background="0"
										color="--red"
										type="link"
										text-decoration-line="initial"
									>
										Read more   &gt;&gt;
									</Button>
									{"            "}
								</Box>
								{"          "}
							</Box>
							{"        "}
						</Box>
						{"                      "}
					</Box>
					{"      "}
					<Box
						display="flex"
						align-self="stretch"
						align-items="center"
						flex-direction="column"
						justify-content="center"
						flex-wrap="no-wrap"
						flex="1 1 0%"
					>
						<Image
							height="100%"
							src="https://uploads.quarkly.io/66b79a6ce778710018d27b85/images/PW24_TueJan30_0218-920.jpg?v=2025-03-09T14:56:36.641Z"
							md-max-width="100%"
							md-height="auto"
							md-width="auto"
							object-fit="cover"
							width="100%"
							object-position="50% 50%"
							align-self="stretch"
							srcSet="https://smartuploads.quarkly.io/66b79a6ce778710018d27b85/images/PW24_TueJan30_0218-920.jpg?v=2025-03-09T14%3A56%3A36.641Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/66b79a6ce778710018d27b85/images/PW24_TueJan30_0218-920.jpg?v=2025-03-09T14%3A56%3A36.641Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/66b79a6ce778710018d27b85/images/PW24_TueJan30_0218-920.jpg?v=2025-03-09T14%3A56%3A36.641Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/66b79a6ce778710018d27b85/images/PW24_TueJan30_0218-920.jpg?v=2025-03-09T14%3A56%3A36.641Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/66b79a6ce778710018d27b85/images/PW24_TueJan30_0218-920.jpg?v=2025-03-09T14%3A56%3A36.641Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/66b79a6ce778710018d27b85/images/PW24_TueJan30_0218-920.jpg?v=2025-03-09T14%3A56%3A36.641Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/66b79a6ce778710018d27b85/images/PW24_TueJan30_0218-920.jpg?v=2025-03-09T14%3A56%3A36.641Z&quality=85&w=3200 3200w"
							sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 1100px) 100vw,100vw"
						/>
					</Box>
					{"    "}
				</Box>
				<Box
					padding-left="64px"
					background="rgba(255, 255, 255, 0.90)"
					border="1.50px solid"
					justify-content="center"
					grid-gap="64px"
					display="flex"
					md-display="flex"
					md-flex-direction="column"
					md-flex-wrap="no-wrap"
					md-align-items="center"
					md-justify-content="center"
					md-padding="0px 0px 0px 0px"
					md-height="auto"
					md-grid-row-gap="0px"
					flex-direction="row"
					flex-wrap="no-wrap"
					align-items="center"
					padding="0px 64px 0px 0px"
				>
					{"      "}
					<Box
						display="flex"
						align-self="stretch"
						align-items="center"
						flex-direction="column"
						justify-content="center"
						flex-wrap="no-wrap"
						flex="1 1 0%"
					>
						<Image
							height="100%"
							src="https://uploads.quarkly.io/66b79a6ce778710018d27b85/images/ADIPEC_2024_Logo_-_02271-2048x1674.png?v=2025-03-18T20:41:33.922Z"
							md-max-width="100%"
							md-height="auto"
							md-width="auto"
							object-fit="cover"
							width="100%"
							object-position="50% 50%"
							align-self="stretch"
							srcSet="https://smartuploads.quarkly.io/66b79a6ce778710018d27b85/images/ADIPEC_2024_Logo_-_02271-2048x1674.png?v=2025-03-18T20%3A41%3A33.922Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/66b79a6ce778710018d27b85/images/ADIPEC_2024_Logo_-_02271-2048x1674.png?v=2025-03-18T20%3A41%3A33.922Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/66b79a6ce778710018d27b85/images/ADIPEC_2024_Logo_-_02271-2048x1674.png?v=2025-03-18T20%3A41%3A33.922Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/66b79a6ce778710018d27b85/images/ADIPEC_2024_Logo_-_02271-2048x1674.png?v=2025-03-18T20%3A41%3A33.922Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/66b79a6ce778710018d27b85/images/ADIPEC_2024_Logo_-_02271-2048x1674.png?v=2025-03-18T20%3A41%3A33.922Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/66b79a6ce778710018d27b85/images/ADIPEC_2024_Logo_-_02271-2048x1674.png?v=2025-03-18T20%3A41%3A33.922Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/66b79a6ce778710018d27b85/images/ADIPEC_2024_Logo_-_02271-2048x1674.png?v=2025-03-18T20%3A41%3A33.922Z&quality=85&w=3200 3200w"
							sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 1100px) 100vw,100vw"
						/>
					</Box>
					<Box
						align-self="stretch"
						padding-top="64px"
						padding-bottom="56px"
						flex-direction="column"
						justify-content="space-between"
						align-items="flex-start"
						display="inline-flex"
						md-height="auto"
						md-padding="0px 0px 0px 0px"
						md-margin="32px 24px 24px 24px"
						flex="1 1 0%"
					>
						<Box
							align-self="stretch"
							flex-direction="column"
							justify-content="flex-start"
							align-items="flex-start"
							grid-gap="40px"
							display="flex"
							md-height="auto"
						>
							{"          "}
							<Box
								align-self="stretch"
								flex-direction="column"
								justify-content="flex-start"
								align-items="flex-start"
								grid-gap="32px"
								display="flex"
								md-height="auto"
							>
								{"            "}
								<Box
									align-self="stretch"
									flex-direction="column"
									justify-content="flex-start"
									align-items="flex-start"
									grid-gap="16px"
									display="flex"
									md-height="auto"
								>
									{"              "}
									<Text
										align-self="stretch"
										color="#080808"
										font=" 700 42px Epilogue"
										line-height="50px"
										word-wrap="break-word"
										margin="0px 0px 0px 0px"
										md-height="auto"
										md-font="600 21px/28px Epilogue"
									>
										ADIPEC2024: Global Energy Sector Looks to SPhotonix to Solve Data Storage Challenges
									</Text>
									{"              "}
									<Text
										align-self="stretch"
										color="#212121"
										font=" 400 21px Epilogue"
										line-height="32px"
										word-wrap="break-word"
										margin="0px 0px 20px 0px"
										md-height="auto"
										md-font="400 15px/24px Epilogue"
									>
										SPhotonix unveils its 5D memory crystal storage at ADIPEC 2024, offering up to 360TB capacity with unmatched longevity and minimal environmental impact. Developed by Prof. Peter Kazansky at the University of Southampton, this breakthrough addresses the energy sector’s growing data demands. A live demo on November 4th will showcase real-time data inscription. CEO Ilya Kazansky highlights the urgent need for sustainable storage as AI-driven data use soars.
									</Text>
									{"          "}
									<Button
										font="--headline3"
										href="/adipec2024"
										padding="0 0 0 0"
										background="0"
										color="--red"
										type="link"
										text-decoration-line="initial"
									>
										Read more   &gt;&gt;
									</Button>
									{"  "}
								</Box>
								{"          "}
							</Box>
							{"        "}
						</Box>
						{"                      "}
					</Box>
					{"          "}
				</Box>
				<Box
					padding-left="64px"
					background="rgba(255, 255, 255, 0.90)"
					border="1.50px solid"
					justify-content="center"
					grid-gap="64px"
					display="flex"
					md-display="flex"
					md-flex-direction="column-reverse"
					md-flex-wrap="no-wrap"
					md-align-items="center"
					md-justify-content="center"
					md-padding="0px 0px 0px 0px"
					md-height="auto"
					md-grid-row-gap="0px"
					flex-direction="row"
					flex-wrap="no-wrap"
					align-items="center"
				>
					{"      "}
					<Box
						align-self="stretch"
						padding-top="64px"
						padding-bottom="56px"
						flex-direction="column"
						justify-content="space-between"
						align-items="flex-start"
						display="inline-flex"
						md-height="auto"
						md-padding="0px 0px 0px 0px"
						md-margin="32px 24px 24px 24px"
						flex="1 1 0%"
					>
						<Box
							align-self="stretch"
							flex-direction="column"
							justify-content="flex-start"
							align-items="flex-start"
							grid-gap="40px"
							display="flex"
							md-height="auto"
						>
							{"          "}
							<Box
								align-self="stretch"
								flex-direction="column"
								justify-content="flex-start"
								align-items="flex-start"
								grid-gap="32px"
								display="flex"
								md-height="auto"
							>
								{"            "}
								<Box
									align-self="stretch"
									flex-direction="column"
									justify-content="flex-start"
									align-items="flex-start"
									grid-gap="16px"
									display="flex"
									md-height="auto"
								>
									{"              "}
									<Text
										align-self="stretch"
										color="#080808"
										font=" 700 42px Epilogue"
										line-height="50px"
										word-wrap="break-word"
										margin="0px 0px 0px 0px"
										md-height="auto"
										md-font="600 21px/28px Epilogue"
									>
										Hidden memories to last billions of years with SPhotonix 5D memory crystal in Boucheron jewellery
									</Text>
									{"              "}
									<Text
										align-self="stretch"
										color="#212121"
										font=" 400 21px Epilogue"
										line-height="32px"
										word-wrap="break-word"
										margin="0px 0px 20px 0px"
										md-height="auto"
										md-font="400 15px/24px Epilogue"
									>
										SPhotonix and Boucheron unveil the ‘Quatre 5D Memory’ ring, a groundbreaking fusion of deep tech and high jewelry. Featuring SPhotonix’s 5D memory crystal, the ring preserves nano-etched memories for billions of years. Inspired by Claire Choisne’s childhood and incorporating a unique IRCAM score, this innovation redefines how emotions and artistry can be immortalized in jewelry.
										<Link
											href="/data-storage-innovation"
											overflow-wrap="normal"
											word-break="normal"
											white-space="normal"
											text-indent="0"
											text-overflow="clip"
											hyphens="manual"
											user-select="auto"
											pointer-events="auto"
										/>
									</Text>
									<Button
										font="--headline3"
										href="/adipec2024"
										padding="0 0 0 0"
										background="0"
										color="--red"
										type="link"
										text-decoration-line="initial"
									>
										Read more   &gt;&gt;
									</Button>
									{"            "}
								</Box>
								{"          "}
							</Box>
							{"        "}
						</Box>
						{"                      "}
					</Box>
					{"      "}
					<Box
						display="flex"
						align-self="stretch"
						align-items="center"
						flex-direction="column"
						justify-content="center"
						flex-wrap="no-wrap"
						flex="1 1 0%"
					>
						<Image
							height="100%"
							src="https://uploads.quarkly.io/66b79a6ce778710018d27b85/images/Curatedition_Boucheron_2024-Quatre-5D-Memory-Ring_Feature-1170x780.jpg?v=2025-03-09T14:54:31.810Z"
							md-max-width="100%"
							md-height="auto"
							md-width="auto"
							object-fit="cover"
							width="100%"
							object-position="50% 50%"
							align-self="stretch"
							srcSet="https://smartuploads.quarkly.io/66b79a6ce778710018d27b85/images/Curatedition_Boucheron_2024-Quatre-5D-Memory-Ring_Feature-1170x780.jpg?v=2025-03-09T14%3A54%3A31.810Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/66b79a6ce778710018d27b85/images/Curatedition_Boucheron_2024-Quatre-5D-Memory-Ring_Feature-1170x780.jpg?v=2025-03-09T14%3A54%3A31.810Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/66b79a6ce778710018d27b85/images/Curatedition_Boucheron_2024-Quatre-5D-Memory-Ring_Feature-1170x780.jpg?v=2025-03-09T14%3A54%3A31.810Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/66b79a6ce778710018d27b85/images/Curatedition_Boucheron_2024-Quatre-5D-Memory-Ring_Feature-1170x780.jpg?v=2025-03-09T14%3A54%3A31.810Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/66b79a6ce778710018d27b85/images/Curatedition_Boucheron_2024-Quatre-5D-Memory-Ring_Feature-1170x780.jpg?v=2025-03-09T14%3A54%3A31.810Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/66b79a6ce778710018d27b85/images/Curatedition_Boucheron_2024-Quatre-5D-Memory-Ring_Feature-1170x780.jpg?v=2025-03-09T14%3A54%3A31.810Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/66b79a6ce778710018d27b85/images/Curatedition_Boucheron_2024-Quatre-5D-Memory-Ring_Feature-1170x780.jpg?v=2025-03-09T14%3A54%3A31.810Z&quality=85&w=3200 3200w"
							sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 1100px) 100vw,100vw"
						/>
					</Box>
					{"    "}
				</Box>
				{"          "}
			</Box>
		</Box>
		<Box
			width="100%"
			height="1263px"
			padding-left="190px"
			padding-right="190px"
			padding-top="132px"
			padding-bottom="132px"
			background="url(https://uploads.quarkly.io/66b79a6ce778710018d27b85/images/blogs-bg.png?v=2024-08-18T10:10:26.882Z) 0% 0%/cover scroll"
			flex-direction="column"
			justify-content="flex-start"
			align-items="center"
			grid-gap="72px"
			display="none"
			sm-padding="60px 16px 60px 16px"
			sm-height="auto"
			padding="132px 190px 132px 190px"
			sm-display="none"
		>
			<Box
				width="1348px"
				height="210px"
				flex-direction="column"
				justify-content="flex-start"
				align-items="flex-start"
				grid-gap="64px"
				display="inline-flex"
				sm-width="100%"
			>
				{"  "}
				<Box
					align-self="stretch"
					justify-content="flex-start"
					align-items="flex-start"
					grid-gap="160px"
					display="inline-flex"
				>
					{"    "}
					<Box
						flex="1 1 0"
						flex-direction="column"
						justify-content="flex-start"
						align-items="flex-start"
						grid-gap="24px"
						display="inline-flex"
						sm-width="100%"
					>
						{"      "}
						<Text
							color="#F44450"
							font="500 16px Epilogue"
							text-transform="uppercase"
							line-height="18px"
							letter-spacing="0.80px"
							word-wrap="break-word"
							margin="0px 0px 0px 0px"
							sm-display="none"
						>
							Key takeaway
						</Text>
						{"      "}
						<Box
							align-self="stretch"
							height="168px"
							flex-direction="column"
							justify-content="flex-start"
							align-items="flex-start"
							grid-gap="28px"
							display="flex"
							sm-width="100%"
							sm-height="auto"
						>
							{"        "}
							<Text
								width="789px"
								color="white"
								font="600 72px Epilogue"
								line-height="76px"
								word-wrap="break-word"
								margin="0px 0px 0px 0px"
								sm-font="600 28px/32px Epilogue"
								sm-width="100%"
							>
								Blogs
							</Text>
							{"        "}
							<Box
								align-self="stretch"
								justify-content="flex-start"
								align-items="flex-end"
								grid-gap="28px"
								display="inline-flex"
							>
								{"                    "}
								<Text
									flex="1 1 0"
									opacity={0.5}
									color="white"
									font="400 21px Epilogue"
									line-height="32px"
									word-wrap="break-word"
									margin="0px 0px 0px 0px"
									sm-width="100%"
								>
									SPhotonix executives and influencers bring insights          and opinions from the world’s largest compute ecosystem.
								</Text>
								<Box
									justify-content="center"
									align-items="flex-start"
									grid-gap="16px"
									display="flex"
									sm-display="none"
								>
									{"                        "}
									<Box
										padding-left="24px"
										padding-right="24px"
										padding-top="22px"
										padding-bottom="22px"
										transform-origin="0 0"
										opacity={0.4}
										border-radius="80px"
										border="2px white solid"
										justify-content="flex-start"
										align-items="flex-start"
										grid-gap="10px"
										display="flex"
									>
										<Image src="https://uploads.quarkly.io/66b79a6ce778710018d27b85/images/arrow.svg?v=2024-08-18T10:15:04.732Z" display="block" />
										{"                          "}
									</Box>
									<Box
										padding-left="24px"
										padding-right="24px"
										padding-top="22px"
										padding-bottom="22px"
										border-radius="80px"
										border="2px white solid"
										justify-content="flex-start"
										align-items="flex-start"
										grid-gap="10px"
										display="flex"
									>
										<Image src="https://uploads.quarkly.io/66b79a6ce778710018d27b85/images/arrow.svg?v=2024-08-18T10:15:04.732Z" display="block" transform="rotate(180deg)" />
									</Box>
									{"          "}
								</Box>
								{"        "}
							</Box>
							{"      "}
						</Box>
						{"    "}
					</Box>
					{"  "}
				</Box>
			</Box>
			<Box
				width="1348px"
				height="717px"
				justify-content="flex-start"
				align-items="center"
				grid-gap="2px"
				display="flex"
				sm-width="100%"
				sm-display="flex"
				sm-flex-direction="column"
				sm-flex-wrap="no-wrap"
				sm-height="auto"
				flex-direction="row"
				flex-wrap="no-wrap"
			>
				{"      "}
				<Box
					align-self="stretch"
					padding="32px"
					background="rgba(255, 255, 255, 0.90)"
					border="1.50px white solid"
					flex-direction="column"
					justify-content="flex-start"
					align-items="flex-start"
					grid-gap="24px"
					display="inline-flex"
					sm-padding="8px 8px 24px 8px"
					max-width="33.33%"
					sm-max-width="initial"
				>
					{"    "}
					<Box
						align-self="stretch"
						height="586px"
						flex-direction="column"
						justify-content="flex-start"
						align-items="flex-start"
						grid-gap="32px"
						display="flex"
						sm-height="auto"
					>
						{"      "}
						<Image
							align-self="stretch"
							height="312px"
							src="https://uploads.quarkly.io/66d0640c995d120018f37959/images/Cloud-Scale%20Archival%20Storage%20Using%20Ultrafast%20Laser%20Nanostructuring-1.png?v=2024-08-31T13:16:00.489Z"
							object-fit="cover"
							sm-max-height="180px"
							srcSet="https://smartuploads.quarkly.io/66d0640c995d120018f37959/images/Cloud-Scale%20Archival%20Storage%20Using%20Ultrafast%20Laser%20Nanostructuring-1.png?v=2024-08-31T13%3A16%3A00.489Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/66d0640c995d120018f37959/images/Cloud-Scale%20Archival%20Storage%20Using%20Ultrafast%20Laser%20Nanostructuring-1.png?v=2024-08-31T13%3A16%3A00.489Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/66d0640c995d120018f37959/images/Cloud-Scale%20Archival%20Storage%20Using%20Ultrafast%20Laser%20Nanostructuring-1.png?v=2024-08-31T13%3A16%3A00.489Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/66d0640c995d120018f37959/images/Cloud-Scale%20Archival%20Storage%20Using%20Ultrafast%20Laser%20Nanostructuring-1.png?v=2024-08-31T13%3A16%3A00.489Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/66d0640c995d120018f37959/images/Cloud-Scale%20Archival%20Storage%20Using%20Ultrafast%20Laser%20Nanostructuring-1.png?v=2024-08-31T13%3A16%3A00.489Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/66d0640c995d120018f37959/images/Cloud-Scale%20Archival%20Storage%20Using%20Ultrafast%20Laser%20Nanostructuring-1.png?v=2024-08-31T13%3A16%3A00.489Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/66d0640c995d120018f37959/images/Cloud-Scale%20Archival%20Storage%20Using%20Ultrafast%20Laser%20Nanostructuring-1.png?v=2024-08-31T13%3A16%3A00.489Z&quality=85&w=3200 3200w"
							sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 1100px) 100vw,100vw"
						/>
						{"      "}
						<Box
							align-self="stretch"
							height="242px"
							flex-direction="column"
							justify-content="flex-start"
							align-items="flex-start"
							grid-gap="16px"
							display="flex"
							sm-padding="0px 16px 0px 16px"
							sm-height="auto"
						>
							{"        "}
							<Text
								color="#959595"
								font="500 16px Epilogue"
								text-transform="uppercase"
								line-height="18px"
								letter-spacing="0.80px"
								word-wrap="break-word"
								margin="0px 0px 0px 0px"
								sm-display="block"
								display="none"
							>
								Jun 18, 2024
							</Text>
							<Text
								align-self="stretch"
								color="#080808"
								font="600 28px Epilogue"
								line-height="32px"
								word-wrap="break-word"
								margin="0px 0px 0px 0px"
							>
								Cloud-Scale Archival Storage Using Ultrafast Laser Nanostructuring
							</Text>
							{"        "}
							<Text
								align-self="stretch"
								color="#212121"
								font="400 17px Epilogue"
								line-height="26px"
								word-wrap="break-word"
								margin="0px 0px 0px 0px"
							>
								Recent progress in ultrafast laser nanostructuring enables high-density multidimensional volumetric data writing. With exceptional media longevity, this could transform archival cloud storage.
							</Text>
							{"      "}
						</Box>
						{"    "}
					</Box>
					{"    "}
					<Box
						align-self="stretch"
						height="43px"
						flex-direction="column"
						justify-content="flex-start"
						align-items="flex-start"
						grid-gap="24px"
						display="flex"
						sm-display="none"
					>
						{"      "}
						<Box align-self="stretch" height="1px" background="#B1B1B1" />
						{"      "}
						<Box align-self="stretch" justify-content="space-between" align-items="center" display="inline-flex">
							{"        "}
							<Text
								color="#959595"
								font="500 16px Epilogue"
								text-transform="uppercase"
								line-height="18px"
								letter-spacing="0.80px"
								word-wrap="break-word"
								margin="0px 0px 0px 0px"
							>
								Jun 18, 2024
							</Text>
							{"        "}
							<Text
								color="#959595"
								font="500 16px Epilogue"
								text-transform="uppercase"
								line-height="18px"
								letter-spacing="0.80px"
								word-wrap="break-word"
								margin="0px 0px 0px 0px"
							>
								Share
							</Text>
							{"      "}
						</Box>
						{"    "}
					</Box>
					{"  "}
				</Box>
				<Box
					align-self="stretch"
					padding="32px"
					background="rgba(255, 255, 255, 0.90)"
					border="1.50px white solid"
					flex-direction="column"
					justify-content="flex-start"
					align-items="flex-start"
					grid-gap="24px"
					display="inline-flex"
					sm-padding="8px 8px 24px 8px"
					flex="1 1 30%"
					max-width="33.33%"
					sm-max-width="initial"
				>
					{"    "}
					<Box
						align-self="stretch"
						height="586px"
						flex-direction="column"
						justify-content="flex-start"
						align-items="flex-start"
						grid-gap="32px"
						display="flex"
						sm-height="auto"
					>
						{"      "}
						<Image
							align-self="stretch"
							height="312px"
							src="https://uploads.quarkly.io/66d0640c995d120018f37959/images/The%20tiny%20glass%20blocks%20that%20can%20preserve%20your%20data%20for%20centuries.png?v=2024-08-31T13:06:17.830Z"
							object-fit="cover"
							sm-max-height="180px"
							srcSet="https://smartuploads.quarkly.io/66d0640c995d120018f37959/images/The%20tiny%20glass%20blocks%20that%20can%20preserve%20your%20data%20for%20centuries.png?v=2024-08-31T13%3A06%3A17.830Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/66d0640c995d120018f37959/images/The%20tiny%20glass%20blocks%20that%20can%20preserve%20your%20data%20for%20centuries.png?v=2024-08-31T13%3A06%3A17.830Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/66d0640c995d120018f37959/images/The%20tiny%20glass%20blocks%20that%20can%20preserve%20your%20data%20for%20centuries.png?v=2024-08-31T13%3A06%3A17.830Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/66d0640c995d120018f37959/images/The%20tiny%20glass%20blocks%20that%20can%20preserve%20your%20data%20for%20centuries.png?v=2024-08-31T13%3A06%3A17.830Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/66d0640c995d120018f37959/images/The%20tiny%20glass%20blocks%20that%20can%20preserve%20your%20data%20for%20centuries.png?v=2024-08-31T13%3A06%3A17.830Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/66d0640c995d120018f37959/images/The%20tiny%20glass%20blocks%20that%20can%20preserve%20your%20data%20for%20centuries.png?v=2024-08-31T13%3A06%3A17.830Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/66d0640c995d120018f37959/images/The%20tiny%20glass%20blocks%20that%20can%20preserve%20your%20data%20for%20centuries.png?v=2024-08-31T13%3A06%3A17.830Z&quality=85&w=3200 3200w"
							sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 1100px) 100vw,100vw"
						/>
						{"      "}
						<Box
							align-self="stretch"
							height="242px"
							flex-direction="column"
							justify-content="flex-start"
							align-items="flex-start"
							grid-gap="16px"
							display="flex"
							sm-padding="0px 16px 0px 16px"
							sm-height="auto"
						>
							{"        "}
							<Text
								color="#959595"
								font="500 16px Epilogue"
								text-transform="uppercase"
								line-height="18px"
								letter-spacing="0.80px"
								word-wrap="break-word"
								margin="0px 0px 0px 0px"
								sm-display="block"
								display="none"
							>
								Jun 18, 2024
							</Text>
							<Text
								align-self="stretch"
								color="#080808"
								font="600 28px Epilogue"
								line-height="32px"
								word-wrap="break-word"
								margin="0px 0px 0px 0px"
							>
								The tiny glass blocks that can preserve your data for centuries
							</Text>
							{"        "}
							<Text
								align-self="stretch"
								color="#212121"
								font="400 17px Epilogue"
								line-height="26px"
								word-wrap="break-word"
								margin="0px 0px 0px 0px"
							>
								For years governments, hospitals and families have had to use frail magnetic storage for their most important data. Now, scientists have an alternative — that lasts for ever.
							</Text>
							{"      "}
						</Box>
						{"    "}
					</Box>
					{"    "}
					<Box
						align-self="stretch"
						height="43px"
						flex-direction="column"
						justify-content="flex-start"
						align-items="flex-start"
						grid-gap="24px"
						display="flex"
						sm-display="none"
					>
						{"      "}
						<Box align-self="stretch" height="1px" background="#B1B1B1" />
						{"      "}
						<Box align-self="stretch" justify-content="space-between" align-items="center" display="inline-flex">
							{"        "}
							<Text
								color="#959595"
								font="500 16px Epilogue"
								text-transform="uppercase"
								line-height="18px"
								letter-spacing="0.80px"
								word-wrap="break-word"
								margin="0px 0px 0px 0px"
							>
								Jun 18, 2024
							</Text>
							{"        "}
							<Text
								color="#959595"
								font="500 16px Epilogue"
								text-transform="uppercase"
								line-height="18px"
								letter-spacing="0.80px"
								word-wrap="break-word"
								margin="0px 0px 0px 0px"
							>
								Share
							</Text>
							{"      "}
						</Box>
						{"    "}
					</Box>
					{"  "}
				</Box>
				<Box
					align-self="stretch"
					padding="32px"
					background="rgba(255, 255, 255, 0.90)"
					border="1.50px white solid"
					flex-direction="column"
					justify-content="flex-start"
					align-items="flex-start"
					grid-gap="24px"
					display="inline-flex"
					sm-padding="8px 8px 24px 8px"
					flex="1 1 30%"
					max-width="33.33%"
					sm-max-width="initial"
				>
					{"    "}
					<Box
						align-self="stretch"
						height="586px"
						flex-direction="column"
						justify-content="flex-start"
						align-items="flex-start"
						grid-gap="32px"
						display="flex"
						sm-height="auto"
					>
						{"      "}
						<Image
							align-self="stretch"
							height="312px"
							src="https://uploads.quarkly.io/66d0640c995d120018f37959/images/Cloud-Scale%20Archival%20Storage%20Using%20Ultrafast%20Laser%20Nanostructuring.png?v=2024-08-31T12:51:49.830Z"
							object-fit="cover"
							sm-max-height="180px"
							srcSet="https://smartuploads.quarkly.io/66d0640c995d120018f37959/images/Cloud-Scale%20Archival%20Storage%20Using%20Ultrafast%20Laser%20Nanostructuring.png?v=2024-08-31T12%3A51%3A49.830Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/66d0640c995d120018f37959/images/Cloud-Scale%20Archival%20Storage%20Using%20Ultrafast%20Laser%20Nanostructuring.png?v=2024-08-31T12%3A51%3A49.830Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/66d0640c995d120018f37959/images/Cloud-Scale%20Archival%20Storage%20Using%20Ultrafast%20Laser%20Nanostructuring.png?v=2024-08-31T12%3A51%3A49.830Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/66d0640c995d120018f37959/images/Cloud-Scale%20Archival%20Storage%20Using%20Ultrafast%20Laser%20Nanostructuring.png?v=2024-08-31T12%3A51%3A49.830Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/66d0640c995d120018f37959/images/Cloud-Scale%20Archival%20Storage%20Using%20Ultrafast%20Laser%20Nanostructuring.png?v=2024-08-31T12%3A51%3A49.830Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/66d0640c995d120018f37959/images/Cloud-Scale%20Archival%20Storage%20Using%20Ultrafast%20Laser%20Nanostructuring.png?v=2024-08-31T12%3A51%3A49.830Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/66d0640c995d120018f37959/images/Cloud-Scale%20Archival%20Storage%20Using%20Ultrafast%20Laser%20Nanostructuring.png?v=2024-08-31T12%3A51%3A49.830Z&quality=85&w=3200 3200w"
							sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 1100px) 100vw,100vw"
						/>
						{"      "}
						<Box
							align-self="stretch"
							height="242px"
							flex-direction="column"
							justify-content="flex-start"
							align-items="flex-start"
							grid-gap="16px"
							display="flex"
							sm-padding="0px 16px 0px 16px"
							sm-height="auto"
						>
							{"        "}
							<Text
								color="#959595"
								font="500 16px Epilogue"
								text-transform="uppercase"
								line-height="18px"
								letter-spacing="0.80px"
								word-wrap="break-word"
								margin="0px 0px 0px 0px"
								sm-display="block"
								display="none"
							>
								Jun 18, 2024
							</Text>
							<Text
								align-self="stretch"
								color="#080808"
								font="600 28px Epilogue"
								line-height="32px"
								word-wrap="break-word"
								margin="0px 0px 0px 0px"
							>
								Cloud-Scale Archival Storage Using Ultrafast Laser Nanostructuring
							</Text>
							{"        "}
							<Text
								align-self="stretch"
								color="#212121"
								font="400 17px Epilogue"
								line-height="26px"
								word-wrap="break-word"
								margin="0px 0px 0px 0px"
							>
								Recent progress in ultrafast laser nanostructuring enables high-density multidimensional volumetric data writing. With exceptional media longevity, this could transform archival cloud storage.
							</Text>
							{"      "}
						</Box>
						{"    "}
					</Box>
					{"    "}
					<Box
						align-self="stretch"
						height="43px"
						flex-direction="column"
						justify-content="flex-start"
						align-items="flex-start"
						grid-gap="24px"
						display="flex"
						sm-display="none"
					>
						{"      "}
						<Box align-self="stretch" height="1px" background="#B1B1B1" />
						{"      "}
						<Box align-self="stretch" justify-content="space-between" align-items="center" display="inline-flex">
							{"        "}
							<Text
								color="#959595"
								font="500 16px Epilogue"
								text-transform="uppercase"
								line-height="18px"
								letter-spacing="0.80px"
								word-wrap="break-word"
								margin="0px 0px 0px 0px"
							>
								Jun 18, 2024
							</Text>
							{"        "}
							<Text
								color="#959595"
								font="500 16px Epilogue"
								text-transform="uppercase"
								line-height="18px"
								letter-spacing="0.80px"
								word-wrap="break-word"
								margin="0px 0px 0px 0px"
							>
								Share
							</Text>
							{"      "}
						</Box>
						{"    "}
					</Box>
					{"  "}
				</Box>
			</Box>
		</Box>
		<Box
			width="100%"
			flex-direction="column"
			justify-content="flex-start"
			align-items="flex-start"
			grid-gap="140px"
			display="inline-flex"
			md-display="flex"
			md-padding="56px 16px 24px 16px"
			md-height="min-content"
			md-grid-row-gap="64px"
			padding="100px 16px 24px 16px"
			background="white"
		>
			{"    "}
			<Box
				align-self="stretch"
				justify-content="flex-start"
				align-items="flex-start"
				grid-gap="80px"
				display="inline-flex"
				md-display="flex"
				md-flex-direction="column"
				md-flex-wrap="no-wrap"
				md-align-items="center"
				md-justify-content="center"
				md-grid-row-gap="24px"
				max-width="1348px"
				margin="0px auto 0px auto"
				width="100%"
			>
				{"    "}
				<Box
					flex="1 1 0"
					flex-direction="column"
					justify-content="flex-start"
					align-items="flex-start"
					grid-gap="28px"
					display="inline-flex"
					md-width="100%"
					md-padding="0px 0px 0px 0px"
					md-height="fit-content"
					md-align-items="center"
					md-display="flex"
					md-flex-direction="column"
					md-justify-content="center"
					md-flex-wrap="no-wrap"
					md-grid-column-gap="0px"
					md-grid-row-gap="8px"
					md-max-width="460px"
				>
					{"      "}
					<Box
						align-self="stretch"
						flex-direction="column"
						justify-content="flex-start"
						align-items="flex-start"
						grid-gap="24px"
						display="flex"
					>
						{"        "}
						<Text
							color="#080808"
							font="600 72px Epilogue"
							line-height="76px"
							word-wrap="break-word"
							margin="0px"
							md-font="600 28px/32px Epilogue"
							md-width="100%"
							text-fill-color="transparent"
							style={{
								"-webkit-text-fill-color": "transparent"
							}}
							background="linear-gradient(96.04deg, #080808 -0.01%, #FC0D1D 42.34%)"
							background-clip="text"
							max-width="100%"
							width="350px"
						>
							Get in touch...
						</Text>
						{"      "}
					</Box>
					{"      "}
					<Text
						align-self="stretch"
						opacity={0.8}
						color="#080808"
						font="400 21px Epilogue"
						line-height="32px"
						word-wrap="break-word"
						margin="0px 0px 0px 0px"
						md-width="100%"
						md-font="400 17px/26px Epilogue"
					>
						<Strong>
							<Link href="mailto:info@sphotonix.com?Web Enquiry: ">
								info@sphotonix.com
							</Link>
						</Strong>
					</Text>
					{"    "}
				</Box>
				{"    "}
				<Components.MakeForm
					md-width="100%"
					max-width="460px"
					sm-max-width="100%"
					action="https://hook.eu2.make.com/1rbbfu2qo4r932bgoqqy8wdizju2pxtr"
					method="post"
				>
					<Override
						slot="Form"
						flex-direction="column"
						justify-content="flex-start"
						align-items="flex-start"
						grid-gap="16px"
						display="inline-flex"
					/>
					<Override
						slot="Content"
						display="flex"
						flex-direction="column"
						flex-wrap="no-wrap"
						grid-row-gap="16px"
					/>
					<Override slot="Error Text" />
					<Override slot="Success Text" font="--bodyText17Regular" />
					{"      "}
					<Text
						align-self="stretch"
						color="#212121"
						font="400 17px Epilogue"
						line-height="26px"
						word-wrap="break-word"
						margin="0px 0px 0px 0px"
						md-font="400 17px/26px Epilogue"
					>
						Stay ahead with the latest breakthroughs in optical nanotechnology! Discover how SPhotonix is revolutionizing data storage and optical innovation. Join our community for exclusive insights and updates!
					</Text>
					{"      "}
					<Components.MfInput name="name" type="text" width="100%">
						<Override
							slot="Input"
							display="inline-flex"
							placeholder-opacity={0.4}
							placeholder-color="#080808"
							placeholder-font="400 17px Epilogue"
							placeholder-line-height="26px"
							placeholder-word-wrap="break-word"
							width="100%"
							padding-left="24px"
							padding-right="24px"
							padding-top="16px"
							padding-bottom="16px"
							border-radius="8px"
							overflow="hidden"
							justify-content="flex-start"
							align-items="flex-start"
							grid-gap="10px"
							md-width="100%"
							placeholder="Name"
							border-color="#B1B1B1"
							border-width="1px"
							border-style="solid"
							focus-border-color=" #B1B1B1"
							background="none"
						/>
					</Components.MfInput>
					<Components.MfInput name="email" type="email" width="100%">
						<Override
							slot="Input"
							display="inline-flex"
							placeholder-opacity={0.4}
							placeholder-color="#080808"
							placeholder-font="400 17px Epilogue"
							placeholder-line-height="26px"
							placeholder-word-wrap="break-word"
							width="100%"
							padding-left="24px"
							padding-right="24px"
							padding-top="16px"
							padding-bottom="16px"
							border-radius="8px"
							overflow="hidden"
							justify-content="flex-start"
							align-items="flex-start"
							grid-gap="10px"
							md-width="100%"
							placeholder="Email"
							border-color="#B1B1B1"
							border-width="1px"
							border-style="solid"
							focus-border-color=" #B1B1B1"
							background="none"
						/>
					</Components.MfInput>
					{"      "}
					<Button
						width="100%"
						padding-left="24px"
						padding-right="24px"
						padding-top="16px"
						padding-bottom="16px"
						background="#EB1E1E"
						border-radius="8px"
						grid-gap="10px"
						color="white"
						font="600 17px Epilogue"
						line-height="26px"
						word-wrap="break-word"
						text-align="center"
						type="submit"
					>
						Subscibe to Newsletter
					</Button>
					{"    "}
				</Components.MakeForm>
				{"  "}
			</Box>
			{"  "}
			<Box
				align-self="stretch"
				height="51px"
				flex-direction="column"
				justify-content="flex-start"
				align-items="flex-start"
				grid-gap="24px"
				display="flex"
				md-display="flex"
				md-grid-row-gap="32px"
				md-height="auto"
				max-width="1348px"
				margin="0px auto 0px auto"
				width="100%"
			>
				{"    "}
				<Box
					align-self="stretch"
					height="1px"
					background="#B1B1B1"
					md-width="100%"
					md-height="1px"
				/>
				<Components.Footer />
				{"      "}
			</Box>
		</Box>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"66b79a6ce778710018d27b83"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}\n\n\nbody  {\nzoom: 0.85;\n}\n"}
			</style>
			<script async={true} src={"https://www.googletagmanager.com/gtag/js?id=G-GFK1N3P5RL"} place={"endOfBody"} rawKey={"670146df6aba74b20ebdd83f"} />
			<script place={"endOfBody"} rawKey={"670147228b237590430d8fce"}>
				{"  window.dataLayer = window.dataLayer || [];\n  function gtag(){dataLayer.push(arguments);}\n  gtag('js', new Date());\n\n  gtag('config', 'G-GFK1N3P5RL');"}
			</script>
			<link
				href={"https://uploads.quarkly.io/66b79a6ce778710018d27b85/images/sphotonix-favicon.ico?v=2025-03-25T15:31:48.817Z"}
				type={"image/x-icon"}
				rel={"icon"}
				place={"endOfHead"}
				rawKey={"67e2cb378695622ce9816272"}
			/>
		</RawHtml>
	</Theme>;
});